import React from "react"
import Footer from "../components/footer"
import "../styles/support.less"
import SEO from "../components/SEO"
import { SolutionTopBanner } from "../components/block"
import { SupportPopButton } from "../components/form-biz"
const loadText = require("src/utils").loadText
const data = loadText("support")
const Detail = () => (
  <div className="support-detail full-bg">
    <div className="font-36 semi-bold t-color">{data.support.name}</div>
    <div className="mobile-padding">
      {data.support.content.map(({ name, details }, i) => (
        <div className="support-detail-item mt-56">
          <div className="imgContainer">
            <picture>
              <source
                srcSet={`/support/support-${i + 1}-m.png 640w`}
                media="(max-width: 640px)"
              />
              <img src={`/support/support-${i + 1}.png`} alt={name} />
            </picture>
            <div className="font-28 semi-bold picTitle">{name}</div>
          </div>
          <div className="content-container textContainer">
            <div className="font-28 semi-bold t-color title">{name}</div>
            <ul className="font-20 mt-24">
              {details.map(c => (
                <li>
                  <span>{c}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </div>
  </div>
)
export default function Home() {
  return (
    <div className="support">
      <SEO {...data.seo} />
      <SolutionTopBanner
        {...data.topBanner}
        height={480}
        button={SupportPopButton}
        bgPic={"/support/topBanner-bg.svg"}
        bannerPic={{
          src: `/support/topBanner-bg-m.svg`,
          style: {
            backgroundPosition: "top center",
          },
        }}
        pic={{
          src: "/support/topBanner-pic.png",
          style: {
            bottom: "-40px",
            right: "85px",
            position: "absolute",
          },
        }}
      />
      <Detail />
      <Footer />
    </div>
  )
}
